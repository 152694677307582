import "./App.css";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, message, Spin, Result } from "antd";
import ReportDNC from "./ReportDNC";
import logo from "../Logo.png";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import config from "../config/config";
import GenericSearch from "./search";

function App() {
  const navigate = useNavigate();

  const [uiSettings, setUiSettings] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUiSettings() {
      const axios = require("axios").default;
      try {
        const response = await axios.get(
          ApiEndpointConstants.UI_SETTINGS_API_ENDPOINT,
          {
            headers: {
              "Access-Control-Allow-Origin": "no-cors",
              ApiKey: config.API_KEY, //INTERNAL_API_KEY,
            },
          }
        );
        const jsonResponse = response.data;

        if (!jsonResponse || Object.keys(jsonResponse).length === 0) {
          message.error("Invalid ApiKey!");
          setError("Invalid ApiKey!");
        } else {
          setUiSettings(jsonResponse);
        }
      } catch (error) {
        message.error("Error fetching UI settings:", error);
        setError("Invalid ApiKey!");
      } finally {
        setLoading(false);
      }
    }

    fetchUiSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spin tip="Please wait. Loading ..." size="large" />
      </div>
    );
  }
  if (error) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={
          <div className="error-message">
            Sorry, you are not authorized to access this page. <br />
            Error: <span>{error}</span>
          </div>
        }
        // extra={<Button type="primary">Back Home</Button>}
      />
    );
  }
  if (uiSettings) {
    return (
      <div className="App">
        <header className="App-header">
          <p
            style={{
              color: "wheat",
              fontSize: 18,
              fontFamily: "serif",
              margin: "0px 10px",
            }}
          >
            TCPA TOOLS
          </p>

          <img src={logo} className="App-logo" alt="logo" />
          <p
            style={{
              color: "wheat",
              fontSize: 18,
              fontFamily: "serif",
              margin: "0px 10px",
            }}
          >
            {uiSettings.header?.middle ? uiSettings.header.middle : ""}
          </p>
          <img src={logo} className="App-logo" alt="logo" />

          <div
            style={{
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            <Button
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                margin: "0px 2px",
              }}
              onClick={() => navigate(`/report-a-dnc`)}
            >
              Report a DNC
            </Button>
          </div>
        </header>

        <section className="App-content">
          <br />
          {/* <TcpaVersionAlert /> */}
          {/* <ReportDNC /> */}
          <GenericSearch uiSettings={uiSettings ?? ""} />

          {/* <NumberSearch uiSettings={uiSettings ?? ""} /> */}
        </section>

        <footer className="App-footer">
          {/* <a
          href="http://allstarbpo.com/"
          style={{ color: "black", fontWeight: "bold" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Copyright {new Date().getFullYear()} © AllStar Software Solutions
        </a> */}
        </footer>
        <Routes>
          <Route path="/report-a-dnc" element={<ReportDNC />} />
        </Routes>
      </div>
    );
  }
}

export default App;
