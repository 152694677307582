import * as yup from "yup";
import { Input, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ReCAPTCHA from "react-google-recaptcha";
import ResponseMessageRenderer from "./ResponseMessageRenderer";
import * as apiHelper from "../common/apiHelper";
import { handleApiError } from "../common/errorHandling";
import {
  targetNameDatasets,
  zipInfoDbsets,
  targetPhoneExtDataSets,
  targetInternalDbDatasets,
} from "./config";

yup.addMethod(yup.string, "onlyDigits", function () {
  return this.matches(/^\d+$/, "Only digits allowed.");
});

const { Search } = Input;

function GenericSearch({ uiSettings }) {
  const [internalPhoneDbResponse, setInternalPhoneDbResponse] = useState(null);
  const [internalNameDbResponse, setInternalNameDbResponse] = useState(null);
  const [externalPhoneDbResponse, setExternalPhoneDbResponse] = useState(null);
  const [captchaKey, setCaptchaKey] = useState(0);
  const [searchedPhone, setSearchedPhone] = useState("");
  const [searchedName, setSearchedName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [phoneInput, setPhoneInput] = useState("");
  const [searchPhoneClicked, setSearchPhoneClicked] = useState(false);
  const [searchNameClicked, setSearchNameClicked] = useState(false);

  const cleanPhoneNumber = (input) => {
    return input.replace(/\D/g, "").slice(0, 10);
  };

  const validatePhoneNumber = (phone) => {
    if (!phone) return "Phone number is required.";
    if (!/^\d{10}$/.test(phone))
      return "Please enter a valid 10-digit phone number.";
    return null;
  };

  const handlePhoneChange = (e) => {
    const cleanedInput = cleanPhoneNumber(e.target.value);

    setPhoneInput(cleanedInput);
  };

  useEffect(() => {
    uiSettings.requireCaptchaVerification &&
      uiSettings.showStringCaptcha &&
      loadCaptchaEnginge(4, "white");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadCaptcha = () => {
    setCaptchaKey((prevKey) => prevKey + 1);
  };

  const handleReCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };

  function handleInternalDbResponseError(response) {
    return {
      closersDnc: `Error: ${response}`,
      invalidPhones: `Error: ${response}`,
      oldClosersDnc: `Error: ${response}`,
      socialAnalytics: `Error: ${response}`,
      voipBlockedPhones: `Error: ${response}`,
      zipInfo: `Error: ${response}`,
    };
  }
  function handleExternalDbResponseError(response) {
    return {
      blr: `Error: ${response}`,
      dsr: `Error: ${response}`,
      tlr: `Error: ${response}`,
    };
  }

  function handleInternalNameDbResponseError(response) {
    return {
      kahootNames: `Error: ${response}`,
      plantif: `Error: ${response}`,
      litigatorName: `Error: ${response}`,
    };
  }
  async function onSearchPhoneNumber(phone) {
    setSearchedPhone("");
    let user_captcha_value =
      document.getElementById("user_captcha_input")?.value;

    if (uiSettings.requireCaptchaVerification && uiSettings.showStringCaptcha) {
      if (validateCaptcha(user_captcha_value) !== true) {
        alert("Captcha Does Not Match");
        return;
      }
    }

    if (uiSettings.requireCaptchaVerification && !isCaptchaVerified) {
      message.error("Captcha verification failed. Please try again.");
      return;
    }

    const validationError = validatePhoneNumber(phone);
    if (validationError) {
      message.error(validationError);
      return;
    }
    setInternalPhoneDbResponse(null);
    setExternalPhoneDbResponse(null);
    setInternalNameDbResponse(null);
    setSearchedPhone(phone);
    setSearchedName("");
    setSearchPhoneClicked(true);

    apiHelper
      .getExternalPhoneDbResponse(phone)
      .then((externalResponse) => {
        setExternalPhoneDbResponse(externalResponse);
      })
      .catch((error) => {
        setExternalPhoneDbResponse(
          handleExternalDbResponseError(handleApiError(error))
        );
      });

    apiHelper
      .getInternalPhoneDbResponse(phone)
      .then((internalResponse) => {
        setInternalPhoneDbResponse(internalResponse);
      })
      .catch((error) => {
        setInternalPhoneDbResponse(
          handleInternalDbResponseError(handleApiError(error))
        );
      });

    setErrorMessage("");
    setIsCaptchaVerified(false);
    reloadCaptcha();
  }

  async function onSearchName(values) {
    setSearchedName("");
    let user_captcha_value =
      document.getElementById("user_captcha_input")?.value;

    if (uiSettings.requireCaptchaVerification && uiSettings.showStringCaptcha) {
      if (validateCaptcha(user_captcha_value) !== true) {
        alert("Captcha Does Not Match");
        return;
      }
    }

    if (uiSettings.requireCaptchaVerification && !isCaptchaVerified) {
      message.error("Captcha verification failed. Please try again.");
      return;
    }
    if (!values) {
      message.error("Please Name.");
      return;
    }

    setInternalPhoneDbResponse(null);
    setExternalPhoneDbResponse(null);
    setSearchNameClicked(true);
    setSearchedName(values);
    setErrorMessage("");
    setInternalNameDbResponse(null);

    apiHelper
      .getInternalNameDbResponse(values)
      .then((internalResponse) => {
        setInternalNameDbResponse(internalResponse);
      })
      .catch((error) => {
        setInternalNameDbResponse(
          handleInternalNameDbResponseError(handleApiError(error))
        );
      });
    setIsCaptchaVerified(false);
    reloadCaptcha();
  }

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          overflowY: "scroll",
        }}
      >
        <div>
          <div style={{ marginBottom: "5px" }}>
            <h1 style={{ color: "Green" }}> Search Phone Number </h1>
            <Search
              value={phoneInput}
              placeholder="Phone Number"
              style={{ width: "300px" }}
              allowClear
              enterButton="Search"
              size="medium"
              onSearch={onSearchPhoneNumber}
              onChange={handlePhoneChange}
              disabled={searchPhoneClicked}
            />
          </div>
          <div>
            <div style={{ marginBottom: "5px" }}>
              <h1 style={{ color: "Green" }}> Search Name </h1>
              <Search
                placeholder=" Name"
                style={{ width: "300px" }}
                allowClear
                enterButton="Search"
                size="medium"
                onSearch={onSearchName}
                disabled={searchNameClicked}
              />
            </div>
          </div>

          {uiSettings.requireCaptchaVerification &&
            uiSettings.showStringCaptcha && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <LoadCanvasTemplate />

                <input
                  style={{ margin: "10px" }}
                  placeholder="Enter Captcha Value"
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                />
              </div>
            )}

          {uiSettings.requireCaptchaVerification && (
            <ReCAPTCHA
              key={captchaKey}
              size="normal"
              sitekey="6Le5p40nAAAAACf9tffCBfoNW95wQCaO9SFpKDJE"
              onChange={handleReCaptchaChange}
            />
          )}
        </div>
        {searchedName && (
          <>
            {targetNameDatasets.map((config) => (
              <ResponseMessageRenderer
                key={config.prefixLabel}
                prefixLabel={config.prefixLabel}
                responseKey={config.responseKey}
                response={internalNameDbResponse}
                messageCallback={config.messageCallback}
              />
            ))}
          </>
        )}

        {searchedPhone && (
          <>
            {zipInfoDbsets.map((config) => (
              <ResponseMessageRenderer
                key={config.prefixLabel}
                prefixLabel={config.prefixLabel}
                responseKey={config.responseKey}
                response={internalPhoneDbResponse}
                messageCallback={config.messageCallback}
              />
            ))}
            {targetPhoneExtDataSets.map((config) => (
              <ResponseMessageRenderer
                key={config.prefixLabel}
                prefixLabel={config.prefixLabel}
                responseKey={config.responseKey}
                response={externalPhoneDbResponse}
                messageCallback={config.messageCallback}
              />
            ))}

            {targetInternalDbDatasets.map((config) => {
              return (
                <div key={config.prefixLabel} style={{ margin: "2px" }}>
                  <ResponseMessageRenderer
                    key={config.prefixLabel}
                    prefixLabel={config.prefixLabel}
                    responseKey={config.responseKey}
                    response={internalPhoneDbResponse}
                    messageCallback={config.messageCallback}
                  />
                </div>
              );
            })}
          </>
        )}
        {errorMessage && (
          <h2>
            <span style={{ color: "red", whiteSpace: "pre-line" }}>Error:</span>{" "}
            <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
          </h2>
        )}
      </div>
    </Fragment>
  );
}

export default GenericSearch;
