export const targetPhoneExtDataSets = [
  {
    prefixLabel: "Litigators DB Suggests: ",
    responseKey: "tlr",
    messageCallback: (response) => {
      if (response) {
        return `${response}`;
      } else {
        return "undefined";
      }
    },
  },
  {
    prefixLabel: "BlackListed DB Suggests: ",
    responseKey: "blr",
    messageCallback: (response) => {
      if (response) {
        return `${response}`;
      } else {
        return "undefined";
      }
    },
  },
  {
    prefixLabel: "DNC Suggests:  ",
    responseKey: "dsr",
    messageCallback: (response) => {
      if (response) {
        return `${response}`;
      } else {
        return "undefined";
      }
    },
  },
];
export const zipInfoDbsets = [
  {
    prefixLabel: "",
    responseKey: "zipInfo",
    messageCallback: (response) => {
      // if (response) {
      return (
        <div
          style={{
            border: "1px dashed black",
            padding: "2px",
          }}
        >
          <span style={{ color: "red" }}>Zip: </span>
          {response?.zip ? response.zip : "N/A"}
          {",   "}
          <span style={{ color: "red" }}> City: </span>
          {response?.city ? response.city : "N/A"}
          {","}
          <span style={{ color: "red" }}> County: </span>
          {response?.county ? response.county : "N/A"}
          {",   "}
          <span style={{ color: "red" }}> State: </span>
          {response?.state ? response.state : "N/A"}
        </div>
      );
      // }
    },
  },
];

export const targetInternalDbDatasets = [
  {
    prefixLabel: "Social Analytics Suggests: ",
    responseKey: "socialAnalytics",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
  {
    prefixLabel: "Closers DNC Suggests: ",
    responseKey: "closersDnc",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
  {
    prefixLabel: "Old Closers DNC Suggests: ",
    responseKey: "oldClosersDnc",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
  {
    prefixLabel: "Invalid Phones Suggests: ",
    responseKey: "invalidPhones",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
  {
    prefixLabel: "VOIP Blocked Suggests: ",
    responseKey: "voipBlockedPhones",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
];

export const targetNameDatasets = [
  {
    prefixLabel: "Kahoot Names Analytics Suggests: ",
    responseKey: "kahootNames",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
  {
    prefixLabel: "Litigators Name Suggests: ",
    responseKey: "litigatorNames",
    messageCallback: (response) => {
      return `${response}`;
    },
  },
];
