const API_ENDPOINT = `https://tcpa-tools-client-api.azurewebsites.net/api`;

// const API_ENDPOINT = `https://localhost:44343/api`;
const API_VERSION = `v2`;

const SEARCH_PHONE_URL = "Search";

const ApiEndpointConstants = {
  UI_SETTINGS_API_ENDPOINT: `${API_ENDPOINT}/clients/ui-settings`,
  INTERNAL_API_ENDPOINT: `${API_ENDPOINT}/${API_VERSION}/${SEARCH_PHONE_URL}/phone/public-int/`,
  INTERNAL_NAME_API_ENDPOINT: `${API_ENDPOINT}/${API_VERSION}/${SEARCH_PHONE_URL}/name/public-int/`,
  INTERNAL_EXT_API_ENDPOINT: `${API_ENDPOINT}/${API_VERSION}/${SEARCH_PHONE_URL}/phone/public-ext/`,
};

export default ApiEndpointConstants;
